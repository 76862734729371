<template>
  <div class="loader-container">
    <div
      class="loader"
      :style="
        `
        width:${size}px;
        height:${size}px;
        border-width:${width}px;
        border-top-color: ${color};
        border-right-color: ${color};
      `
      "
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "30"
    },
    width: {
      type: String,
      default: "3.5"
    },
    color: {
      type: String,
      default: "#9c9c9c"
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    border: 3.5px solid transparent;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
